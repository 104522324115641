import React from 'react';

import { Page } from '../components/layout/page';
import Layout from '../components/layout/layout';
import SEO from '../components/content/seo';
import HeadlineSection from '../components/layout/headline-section';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Page>
      <HeadlineSection
        title={{ lead: `Page`, emphasis: 'Not Found' }}
        subtitle='Oh, dear. Wherever shall you go now?'
        background='white'
        justify='start'
      />
    </Page>
  </Layout>
);

export default NotFoundPage;
