import React from 'react';
import { Button, Col, Row } from 'antd';
import cx from 'classnames';

import styles from './headline-section.module.less';
import './headline-section.less';

const HeadlineSection = props => {
  const {
    children,
    background,
    justify,
    title,
    subtitle,
    button,
    supplement,
    className,
    col1,
    col2,
  } = props;

  const column1 = {
    xs: (col1 && col1.xs) || 24,
    sm: (col1 && col1.sm) || 24,
    md: (col1 && col1.md) || 24,
    lg: (col1 && col1.lg) || 16,
    xl: (col1 && col1.xl) || 12,
  };

  const column2 = {
    xs: (col2 && col2.xs) || 24,
    sm: (col2 && col2.sm) || 24,
    md: (col2 && col2.md) || 24,
    lg: (col2 && col2.lg) || 8,
    xl: (col2 && col2.xl) || 12,
  };

  const Main = () => (
    <Col
      xs={column1.xs}
      sm={column1.sm}
      md={column1.md}
      lg={column1.lg}
      xl={column1.xl}>
      <section className={cx(styles.content)}>
        {title && (
          <h2 className={styles.title}>
            {title.lead}
            {title.emphasis && (
              <span className={styles.emphasis}>{title.emphasis}</span>
            )}
          </h2>
        )}
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
        {children && <div className={styles.body}>{children}</div>}
        {button && button.label && (
          <Button
            className={styles.action}
            type='primary'
            size='large'
            href={button.url}>
            {button.label}
          </Button>
        )}
      </section>
    </Col>
  );

  const Secondary = () => (
    <Col
      xs={column2.xs}
      sm={column2.sm}
      md={column2.md}
      lg={column2.lg}
      xl={column2.xl}>
      {supplement}
    </Col>
  );

  return (
    <div
      className={cx(
        styles.headlineSection,
        styles.typography,
        styles[background],
        className,
      )}>
      <div className={styles.wrapper}>
        <Row gutter={32} type='flex' justify={justify}>
          {justify !== 'end' && (
            <>
              <Main />
              {supplement && <Secondary />}
            </>
          )}
          {justify === 'end' && (
            <>
              {supplement && <Secondary />}
              <Main />
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default HeadlineSection;
